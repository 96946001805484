//Add strings
gdprCookieNoticeLocales.de = {
  description: 'Mit Hilfe von Cookies können wir sicherstellen, dass Sie das beste Erlebnis auf unserer Website haben. Cookies ermöglichen Funktionen wie Social Media Interaktionen, personalisierte Nachrichten und Analysen. Wählen Sie die Cookies, die Sie verwenden möchten und klicken dann auf "Einstellungen speichern". Die WBS OIL respektiert Ihr Recht auf Datenschutz. Aus diesem Grund stellen wir dir dieses Cookie-Management-Tool zur Verfügung, das Sie bei der Verwaltung Ihrer Cookies-Präferenzen unterstützt. Klicken Sie auf die verschiedenen Kategorieüberschriften, um mehr zu erfahren und unsere Standardeinstellungen zu ändern (nur bei unbedingt erforderlichen Cookies ist die Verwendung von Cookies auf dieser Website standardmäßig aktiviert). Das Blockieren einiger Arten von Cookies kann sich jedoch auf Ihre Erfahrung mit der Website und den von uns angebotenen Diensten auswirken. Detaillierte Informationen über den Einsatz von Cookies erhalten Sie unter: "Weitere Informationen zu Cookies".',
  settings: 'Cookie-Einstellungen',
  accept: 'Cookies zulassen',
  statement: 'Weitere Informationen zu Cookies',
  save: 'Einstellungen speichern',
  reject: 'Alles ablehnen',
  always_on: 'Immer aktiv',
  cookie_essential_title: 'Technisch notwendige Cookies',
  cookie_essential_desc: 'Diese Cookies sind zur Funktion unserer Website erforderlich und können in unseren Systemen nicht deaktiviert werden. In der Regel werden diese Cookies nur als Reaktion auf von Ihnen getätigten Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen, dem Anmelden oder dem Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies benachrichtigt werde. Einige Bereiche der Website funktionieren dann aber möglicherweise nicht.',
  cookie_performance_title: 'Präferenz-Cookies',
  cookie_performance_desc: 'Präferenz-Cookies ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte Sprache oder die Region in der Sie sich befinden.',
  cookie_analytics_title: 'Statistik-Cookies',
  cookie_analytics_desc: 'Statistik-Cookies helfen Webseiten-Betreibern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt werden.',
  cookie_marketing_title: 'Marketing-Cookies',
  cookie_marketing_desc: 'Marketing-Cookies werden verwendet, um Besuchern auf Webseiten zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.'
}
